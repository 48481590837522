import { render, staticRenderFns } from "./ImageAnnotations.vue?vue&type=template&id=7db572e5&scoped=true"
import script from "./ImageAnnotations.vue?vue&type=script&lang=js"
export * from "./ImageAnnotations.vue?vue&type=script&lang=js"
import style0 from "./ImageAnnotations.vue?vue&type=style&index=0&id=7db572e5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db572e5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreBoundingBox from '@/components/core/BoundingBox.vue'
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {CoreBoundingBox,VChip,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VMenu,VSubheader})
