import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=1b5ed957&scoped=true"
import script from "./InfoBox.vue?vue&type=script&lang=js"
export * from "./InfoBox.vue?vue&type=script&lang=js"
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=1b5ed957&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5ed957",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreBoundingBoxInfo from '@/components/core/BoundingBoxInfo.vue'
import CoreBoundingBoxListItem from '@/components/core/BoundingBoxListItem.vue'
import CoreFileInfo from '@/components/core/FileInfo.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {CoreBoundingBoxInfo,CoreBoundingBoxListItem,CoreFileInfo,VBtn,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VList,VListItem,VMenu,VVirtualScroll})
