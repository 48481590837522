var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.inverse)?_c('div',{staticClass:"resize-button-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"resize-button",on:{"click":function($event){return _vm.$emit('decrease')}}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(_vm.decIcon))])],1)]}}],null,false,3052939700)},[_c('span',[_vm._v("Shrink")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"resize-button",on:{"click":function($event){return _vm.$emit('increase')}}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(_vm.incIcon))])],1)]}}],null,false,458151540)},[_c('span',[_vm._v("Enlarge")])])],1):_c('div',{staticClass:"resize-button-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"resize-button",on:{"click":function($event){return _vm.$emit('increase')}}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(_vm.incIcon))])],1)]}}])},[_c('span',[_vm._v("Enlarge")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"resize-button",on:{"click":function($event){return _vm.$emit('decrease')}}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(_vm.decIcon))])],1)]}}])},[_c('span',[_vm._v("Shrink")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }