import { render, staticRenderFns } from "./BoundingBoxListItem.vue?vue&type=template&id=0b0b127d&scoped=true"
import script from "./BoundingBoxListItem.vue?vue&type=script&lang=js"
export * from "./BoundingBoxListItem.vue?vue&type=script&lang=js"
import style0 from "./BoundingBoxListItem.vue?vue&type=style&index=0&id=0b0b127d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0b127d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VBtnToggle,VIcon,VImg,VListItem,VListItemAction,VListItemContent})
