import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=d4a3dcc6"
import script from "./AppFooter.vue?vue&type=script&lang=js"
export * from "./AppFooter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreTaskList from '@/components/core/TaskList.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {CoreTaskList,VBtn,VCard,VCardText,VDivider,VFooter})
