import { render, staticRenderFns } from "./DataView.vue?vue&type=template&id=14dfa399&scoped=true"
import script from "./DataView.vue?vue&type=script&lang=js"
export * from "./DataView.vue?vue&type=script&lang=js"
import style0 from "./DataView.vue?vue&type=style&index=0&id=14dfa399&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14dfa399",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AppViewHeader from '@/components/app/ViewHeader.vue'
import CoreCustomPaginator from '@/components/core/CustomPaginator.vue'
import CoreFileInfo from '@/components/core/FileInfo.vue'
import CoreImageUploader from '@/components/core/ImageUploader.vue'
import CoreProjectSelector from '@/components/core/ProjectSelector.vue'
import DialogsImageDelete from '@/components/dialogs/ImageDelete.vue'
import UtilsKeypressHandler from '@/components/utils/KeypressHandler.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {AppViewHeader,CoreCustomPaginator,CoreFileInfo,CoreImageUploader,CoreProjectSelector,DialogsImageDelete,UtilsKeypressHandler,VCard,VCardText,VCardTitle,VCol,VContainer,VDivider,VIcon,VRow,VSpacer})
