var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bounding-box",class:{
      pipeline: _vm.localValue.pipelineGenerated,
      selected: _vm.selected,
      highlight: _vm.highlight,
      hidden: _vm.hidden},style:(_vm.style),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('selectedBBox', _vm.localValue)},"contextmenu":function($event){$event.stopPropagation();return _vm.handleRightClick.apply(null, arguments)}}},[(_vm.hasLabel)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"label lighten-1",class:{
          blue: _vm.localValue.label !== null,
          green: _vm.localValue.label === null,
        }},'div',attrs,false),on),[(_vm.localValue.label !== null)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-tag")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-brain")])],1)]}}],null,false,520152304)},[_c('span',[_vm._v(_vm._s(_vm.label))])]):_vm._e()],1),_c('v-menu',{attrs:{"position-x":_vm.ctxMenuX,"position-y":_vm.ctxMenuY,"absolute":"","offset-y":""},model:{value:(_vm.showCtxMenu),callback:function ($$v) {_vm.showCtxMenu=$$v},expression:"showCtxMenu"}},[_c('v-list',{attrs:{"flat":"","dense":"","subheader":""}},[_c('v-subheader',[_vm._v("Box actions")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.items),function(item,i){return _c('div',{key:i},[(item.separator)?_c('v-divider'):_c('v-list-item',{on:{"click":function($event){return _vm.$emit(item.action, _vm.localValue)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }