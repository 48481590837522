import { render, staticRenderFns } from "./ImageAnnotator.vue?vue&type=template&id=ea5a58be"
import script from "./ImageAnnotator.vue?vue&type=script&lang=js"
export * from "./ImageAnnotator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreImageAnnotations from '@/components/core/ImageAnnotations.vue'
import CoreInfoBox from '@/components/core/InfoBox.vue'
import CoreLazyImage from '@/components/core/LazyImage.vue'
import DialogsAnnotationDialog from '@/components/dialogs/AnnotationDialog.vue'
import DialogsBoundingBoxDelete from '@/components/dialogs/BoundingBoxDelete.vue'
import DialogsBoundingBoxEdit from '@/components/dialogs/BoundingBoxEdit.vue'
import UtilsKeypressHandler from '@/components/utils/KeypressHandler.vue'
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {CoreImageAnnotations,CoreInfoBox,CoreLazyImage,DialogsAnnotationDialog,DialogsBoundingBoxDelete,DialogsBoundingBoxEdit,UtilsKeypressHandler,VCol,VRow})
