import { render, staticRenderFns } from "./CropsView.vue?vue&type=template&id=39d688ee&scoped=true"
import script from "./CropsView.vue?vue&type=script&lang=js"
export * from "./CropsView.vue?vue&type=script&lang=js"
import style0 from "./CropsView.vue?vue&type=style&index=0&id=39d688ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d688ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AppViewHeader from '@/components/app/ViewHeader.vue'
import CoreCroppedImage from '@/components/core/CroppedImage.vue'
import CoreCustomPaginator from '@/components/core/CustomPaginator.vue'
import CoreProjectSelector from '@/components/core/ProjectSelector.vue'
import DialogsAnnotationDialog from '@/components/dialogs/AnnotationDialog.vue'
import DialogsTrackDelete from '@/components/dialogs/TrackDelete.vue'
import UtilsKeypressHandler from '@/components/utils/KeypressHandler.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {AppViewHeader,CoreCroppedImage,CoreCustomPaginator,CoreProjectSelector,DialogsAnnotationDialog,DialogsTrackDelete,UtilsKeypressHandler,VAlert,VBadge,VBtn,VBtnToggle,VCard,VCardSubtitle,VCardText,VCardTitle,VChip,VCol,VContainer,VIcon,VRow,VSlider,VTab,VTabItem,VTabs,VTabsItems})
