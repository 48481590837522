import { render, staticRenderFns } from "./AnnotationsView.vue?vue&type=template&id=66dd3efe"
import script from "./AnnotationsView.vue?vue&type=script&lang=js"
export * from "./AnnotationsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AppViewHeader from '@/components/app/ViewHeader.vue'
import CoreImageAnnotator from '@/components/core/ImageAnnotator.vue'
import CoreImageSelector from '@/components/core/ImageSelector.vue'
import CoreProjectSelector from '@/components/core/ProjectSelector.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {AppViewHeader,CoreImageAnnotator,CoreImageSelector,CoreProjectSelector,VAlert,VCard,VContainer,VFooter})
