import { render, staticRenderFns } from "./BoundingBoxEdit.vue?vue&type=template&id=1556ea64"
import script from "./BoundingBoxEdit.vue?vue&type=script&lang=js"
export * from "./BoundingBoxEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreCroppedImage from '@/components/core/CroppedImage.vue'
import CoreMoveButtons from '@/components/core/MoveButtons.vue'
import CoreResizeButtons from '@/components/core/ResizeButtons.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {CoreCroppedImage,CoreMoveButtons,CoreResizeButtons,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog})
